




































































































































































































































































import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator'
import { FindTituloUseCase, SaveTituloUseCase } from '@/usecases/titulos'
import DatePicker from '@/components/ui/DatePicker.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import {
	Cliente,
	FluxoDoTitulo,
	FormaDePagamento,
	FormDeTitulo,
	Fornecedor,
	Loja,
	Parcelamento,
	SituacaoDoTitulo,
	TipoDePagamento,
	TipoDePessoa,
	Titulo,
} from '@/models'
import { obrigatorio } from '@/shareds/regras-de-form'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import SeletorDePessoa from '@/components/cliente/SeletorDePessoa.vue'
import SeletorDeLojas from '@/components/loja/SeletorDeLojas.vue'
import { criarTitulo } from '@/shareds/titulo'
import { ClienteServiceAdapter, FindBaixaDePagamentoUseCase } from '@/usecases'
import { BaixaDePagamento } from '@/models/financeiro/BaixaDePagamento'
import axios, { CancelTokenSource } from 'axios'
import { DataOptions } from 'vuetify'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import DialogoDeEdicaoDeBaixasDePagamento from './DialogoDeEdicaoDeBaixasDePagamento.vue'
import { DeleteBaixaDePagamentoUseCase } from '@/usecases/baixaDePagamento/DeleteBaixaDePagamentoUseCase'
import { formatarCnpjOuCpf, formatarMoeda } from '@/shareds/formatadores'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { imprimirComprovanteDeRecebivel } from '@/shareds/venda-shareds'
import SeletorDeFornecedor from '@/components/fornecedor/SeletorDeFornecedor.vue'
import SeletorDeCliente from '../clientes/SeletorDeCliente.vue'
import { extrairCnpjOuCpfDaPessoa } from '@/shareds/cliente-shareds'
import SeletorDeFornecedorComBusca from '@/components/fornecedor/SeletorDeFornecedorComBusca.vue'

type AbaDeTitulo = 'Principal' | 'Baixas'
@Component({
	components: {
		DatePicker,
		CampoDecimal,
		SeletorDePessoa,
		SeletorDeLojas,
		DialogoDeEdicaoDeBaixasDePagamento,
		Confirmacao,
		SeletorDeFornecedor,
		SeletorDeFornecedorComBusca,
		SeletorDeCliente,
	},
})
export default class DialogoDeEdicaoDeTitulo extends Vue {
	@Prop() tipoDePessoa!: TipoDePessoa
	@Prop({ type: Boolean, default: false }) retornoCrediario!: boolean
	@Ref() form!: HTMLFormElement
	@Ref() campoDeLoja!: HTMLInputElement
	@Ref() dialogoDeBaixas!: DialogoDeEdicaoDeBaixasDePagamento
	@Ref() confirmacaoDeDeletarBaixa!: Confirmacao
	formulario: FormDeTitulo = criarTitulo('Entrada')
	@Prop() titulo!: Titulo

	obrigatorio = obrigatorio
	formatarMoeda = formatarMoeda

	condicao: string | null = null

	opcoesDeSituacaoDeTitulo: SituacaoDoTitulo[] = [
		'Aberto',
		'Cancelado',
		'Estornado',
		'Pago',
	]

	opcoesDeFormaDePagamento: FormaDePagamento[] = [
		'Cartão',
		'Dinheiro',
		'Voucher',
		'Crediário',
		'TEF Crédito',
		'TEF Débito',
		'TEF Pix',
		'Link',
		'Outros',
	]

	buscando = false
	mostra = false
	show = false

	edicaoFormaPagamento = true
	editandoTitulo = true
	isParcelamento = false
	selecionado: BaixaDePagamento | null = null

	saveTituloUseCase = new SaveTituloUseCase()
	findBaixaDePagamento = new FindBaixaDePagamentoUseCase()
	cancelToken: CancelTokenSource | null = null
	deleteBaixasUseCase = new DeleteBaixaDePagamentoUseCase()
	findTituloUseCase = new FindTituloUseCase()

	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	titulos: Titulo[] = []
	baixasDePagamento: BaixaDePagamento[] = []
	tab: null | number = null
	tabs: AbaDeTitulo[] = []
	tituloParaBaixa: Titulo | null = null
	opcoesDeTiposDePagameto: TipoDePagamento[] | null = null
	cliente: Cliente | string | null = null
	private clienteService!: ClienteServiceAdapter

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	parcelamento: Parcelamento = {
		quantidade: 2,
		recorrencia: 30,
	}

	headers = [
		{ text: 'Transação', value: 'transactionId' },
		{ text: 'Valor Pago', value: 'valorPagamento' },
		{ text: 'Data Pagamento', value: 'dataHoraPagamentoFormatada' },
		{ text: 'Taxa', value: 'isTaxa' },
		{ text: 'Ações', value: 'actions', sortable: false },
	]

	headersSemActions = [
		{ text: 'Transação', value: 'transactionId' },
		{ text: 'Valor Pago', value: 'valorPagamento' },
		{ text: 'Data Pagamento', value: 'dataHoraPagamentoFormatada' },
		{ text: 'Taxa', value: 'isTaxa' },
	]

	get titulosFormatados() {
		return this.titulos.map(titulo => ({
			...titulo,
			formaDePagamento: titulo.formaDePagamento ? titulo.formaDePagamento : '',
		}))
	}

	get baixasDePagamentoFormatados() {
		return this.baixasDePagamento.map(baixa => ({
			...baixa,
			id: baixa.id,
			transactionId: baixa.transactionId,
			valorPagamento: baixa.valorPagamento,
			dataHoraPagamentoFormatada: dateTimeToPtBrFormat(baixa.dataHoraPagamento),
			isTaxa: baixa.isTaxa,
			isIntegracao: baixa.isIntegracao,
		}))
	}

	get abasPermitidas() {
		if(this.retornoCrediario) {
			this.tabs = ['Baixas']
			return this.tabs
		} 

		this.tabs = ['Principal','Baixas']
		return this.tabs
	}

	async imprimirComprovante(baixa: BaixaDePagamento) {
		try {
			
			const baixaFormatada: BaixaDePagamento = {
				id: baixa.id,
				titulo: baixa.titulo,
				transactionId: baixa.transactionId,
				valorPagamento: baixa.valorPagamento,
				dataHoraPagamento: baixa.dataHoraPagamento,
				isTaxa: baixa.isTaxa,
				isIntegracao: baixa.isIntegracao,
			}
			const pdf = await this.findBaixaDePagamento.imprimirBaixaDePagamento(baixaFormatada)
			await imprimirComprovanteDeRecebivel(pdf)
		} catch(error) {
			AlertModule.setError(error)
		}

	}

	esconder(){
		this.baixasDePagamento = []
		this.mostra = false
	}

	atualizaBaixas(baixa: BaixaDePagamento) {
		this.buscarBaixas()
		this.$emit('atualizar')
		this.$emit('valor', baixa)
		this.mostra = false
	}

	exibeConfirmacao(item: BaixaDePagamento | null) {
		if(!item) return
		this.selecionado = JSON.parse(JSON.stringify(item))
		this.confirmacaoDeDeletarBaixa.mostrar()
		
	}

	async buscarBaixas() {
		if (this.cancelToken) this.cancelToken.cancel()

		try {
			this.buscando = true
			this.cancelToken = axios.CancelToken.source()
			const params = {
				tituloId: this.formulario.id,
				page: this.paginacao.page,
				size: this.paginacao.itemsPerPage,
			}

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			const page = await this.findBaixaDePagamento.findByTituloId(
				params,
				axiosConfig,
			)
			this.baixasDePagamento = page.content

			this.buscando = false
		} catch (error) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
			this.buscando = false
		}
	}

	mostrarDialogo(titulo: Titulo | FluxoDoTitulo, condicao: string) {
		this.edicaoFormaPagamento = condicao == 'criar' ? false : true
		this.editandoTitulo = condicao == 'editar'
		this.mostra = true
		if (typeof titulo !== 'string') {
			this.tituloParaBaixa = titulo as Titulo
		}

		this.formulario =
			typeof titulo === 'string'
				? criarTitulo(titulo)
				: {
					...titulo,
					formaDePagamento: titulo.formaDePagamento
						? this.displayPagamento(titulo.formaDePagamento)
						: '',
					id: titulo.id ? titulo.id : '',
					tipoDePagamento: titulo.tipoDePagamento,
				}
		setTimeout(() => {
			this.campoDeLoja.focus()
		}, 250)
		if(this.edicaoFormaPagamento == true) this.buscarBaixas()
	}

	displayPagamento(formaDePagamento: string) {
		let formaFormatada
		if (formaDePagamento == 'CARTAO') formaFormatada = 'Cartão'
		if (formaDePagamento == 'DINHEIRO') formaFormatada = 'Dinheiro'
		if (formaDePagamento == 'VOUCHER') formaFormatada = 'Voucher'
		if (formaDePagamento == 'OUTROS') formaFormatada = 'Outros'
		if (formaDePagamento == 'TEF CREDITO') formaFormatada = 'TEF Crédito'
		if (formaDePagamento == 'TEF DEBITO') formaFormatada = 'TEF Débito'
		if (formaDePagamento == 'CREDIARIO') formaFormatada = 'Crediario'
		return formaFormatada
	}

	get valorFaltante() {		
		if (this.tituloParaBaixa && this.baixasDePagamento && this.tituloParaBaixa.id) {
			return this.tituloParaBaixa.valorAtualizado
		}
		return 0
	}

	abrirDialogoDeCriacaoDeBaixa() {
		const valorFaltante = this.valorFaltante ?? 0;
		this.dialogoDeBaixas.mostrarDialogo(this.tituloParaBaixa, valorFaltante);
	}


	async salvarTitulo() {
		try {
			if (!this.form.validate()) return
			if (!this.formulario) return

			const titulos = await this.saveTituloUseCase.execute(this.formulario, this.parcelamento)

			this.$emit('salvar', titulos)
			this.formulario = criarTitulo('Entrada')
			this.baixasDePagamento = []
			this.mostra = false
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	get itens() {
		return this.baixasDePagamento
	}

	async removerBaixa(baixa: BaixaDePagamento | null) {
		if (!baixa) return

		try {
			await this.deleteBaixasUseCase.execute(baixa.id)
			this.baixasDePagamento = this.baixasDePagamento.filter(
				({ id }) => id !== baixa.id,
			)

			this.formulario.id = this.formulario.id ?? ''; 

			const titulo = await this.findTituloUseCase.findById(this.formulario.id);

			this.formulario = {
				...titulo,
				formaDePagamento: titulo.formaDePagamento
					? this.displayPagamento(titulo.formaDePagamento)
					: '',
				id: titulo.id ? titulo.id : '',
				tipoDePagamento: titulo.tipoDePagamento,
			}
			this.$emit('aplicar')
			this.mostra = false
		} catch (error) {
			AlertModule.setError(error)
		}
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) return this.tituloParaBaixa = null
		window.requestAnimationFrame(() => {
			this.form.resetValidation()
		})
	}
	
	@Watch('formulario.formaDePagamento')
	onChangeFormulario() {
		if (this.formulario.formaDePagamento == 'Dinheiro') return this.formulario.temParcelamento = false
	}

	@Watch('formulario.loja')
	onChangeLoja(lojaAtual: Loja | null, lojaAnterior: Loja | null) {
		if (lojaAtual && lojaAnterior !== lojaAtual) {
			this.opcoesDeTiposDePagameto = lojaAtual.tiposDePagamento
		}
	}

	@Watch('formulario.tipoDePagamento')
	onChangeTipoDePagamento(tipoDePagamentoAtual: TipoDePagamento | null, tipoDePagamentoAnterior: TipoDePagamento | null) {
		if (!tipoDePagamentoAtual && tipoDePagamentoAtual !== tipoDePagamentoAnterior) {
			this.formulario.formaDePagamento = null
		}

		if (tipoDePagamentoAtual && tipoDePagamentoAtual !== tipoDePagamentoAnterior) {
			this.formulario.formaDePagamento = tipoDePagamentoAtual.formaDePagamento
		}
	}
	
	@Watch('formulario.temParcelamento')
	onChangeTemParcelamento() {
		this.show = !this.show
	}

	async fornecedorSelecionadoitem(item: Fornecedor | string) {
		this.cliente = typeof item === 'string' ? await this.getFornecedor(item): await this.getFornecedor(item.cnpjOuCpf) 
	}

	async getFornecedor(cpfOuCnpj: string | null) {
		if (cpfOuCnpj) {
			try {
				return await this.clienteService.get(cpfOuCnpj);
			} catch(err) {
				return cpfOuCnpj
			}
		}
		return cpfOuCnpj
	}

	get cnpjOuCpfFormatado() {
		const cnpjOuCpf = extrairCnpjOuCpfDaPessoa(this.formulario.pessoa)
		return cnpjOuCpf ? formatarCnpjOuCpf(cnpjOuCpf) : ''
	}

}
